//  联盟动态
import React, { useState, useEffect } from "react";
import { AliOss, ThemeColor, CutLine } from "../../lib/const";
import { getNewsList } from "../../apis/index";
import scopeBg from "../../static/imgs/scope_bg.png";
import MagmodeImg1 from "../../static/imgs/magmode1.png";
import "./index.scss";
import store from "../../store/index";

const data = ["小程序（名堂秀）", "后台管理系统 "];
const arr = [
  {
    img: MagmodeImg1,
    url: "https://lanhuapp.com/link/#/invite?sid=qXItegWa",
    content:
      "这是一个由知名作家蔡崇达先生提出的需求，是一款男装品牌销售、展示的微信小程序，内含会员系统、订单系统、商城系统等",
  },
  {
    url: "https://lanhuapp.com/link/#/invite?sid=qX0XoBNr",
    content: "名堂秀对应的后台系统模块展示，因涉及保密机制，仅展示部分模块",
  },
];

const list = [
  {
    sub: "专业的产品设计",
    // text: "超专业的产品设计",
  },
  {
    sub: "细致的需求分析",
    // text: "实现服务转移，提升产业整体竞争力",
  },
  {
    sub: "标准的代码实现",
    // text: "建立公共服务平台，实现知识产权共享",
  },
];

const Cabinet = () => {
  const [inx, setInx] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    store.subscribe(() => {
      setAmount(store.getState().amount);
    });
  }, []);

  return (
    <div className="scope_page">
      <section
        style={{
          height: "100%",
          borderTop: CutLine,
          padding: "0 0.5rem 0 0.5rem",
        }}
      >
        <div
          style={{
            border: CutLine,
            borderBottom: "none",
          }}
        >
          <section
            style={{
              backgroundImage: `url(${scopeBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "3.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ul className="upperUl">
              {list.map((item, index) => {
                return (
                  <li key={index}>
                    <img
                      src={`${AliOss}/new_version_0518/business/business_bg_icon_${
                        index + 1
                      }.png`}
                    />
                    <span
                      style={{
                        fontSize: "0.14rem",
                        fontWeight: "bold",
                        margin: "0.1rem 0 0.05rem 0",
                      }}
                    >
                      {item.sub}
                    </span>
                    <span
                      style={{ fontSize: "0.12rem", fontWeight: "bold" }}
                      className="last_span"
                    >
                      {item.text}
                    </span>
                  </li>
                );
              })}
            </ul>
          </section>
          <main>
            <ul>
              {data.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setInx(index);
                    }}
                  >
                    <span
                      style={{
                        borderBottom:
                          inx == index ? `2px solid ${ThemeColor}` : "none",
                      }}
                    >
                      {item}
                    </span>
                  </li>
                );
              })}
            </ul>
            {arr.map((item, index) => {
              return (
                <div
                  className="content"
                  style={{
                    display: index == inx ? "flex" : "none",
                  }}
                >
                  <article>
                    <div>
                      {item.content}。
                      <a
                        style={{
                          color: "red",
                        }}
                        target="_blank"
                        href={item.url}
                      >
                        点击查看原型图
                      </a>
                    </div>
                  </article>
                  <img
                    src={`${AliOss}/new_version_0518/business/business_tab_${
                      inx + 1
                    }.png`}
                  />
                </div>
              );
            })}
          </main>
        </div>
      </section>
    </div>
  );
};

export default Cabinet;
