// 专业委员会
import React, { useState, useEffect } from "react";
import { Image } from "antd";

import {
  AliOss,
  ThemeColor,
  CutLine,
  barFontSize,
  barHeight,
  IframeUrl,
} from "../../lib/const";
import CuishouImg from "../../static/imgs/cuishou.png";
import CuishouImg1 from "../../static/imgs/cuishou1.png";
import BeidouImg from "../../static/imgs/beidou.png";
import TanzhongheImg from "../../static/imgs/tanzhonghe.png";
import DianziImg from "../../static/imgs/dianzi.png";
import CrmphoneImg from "../../static/imgs/crmphone.jpg";
import CrmphoneImg1 from "../../static/imgs/crmphone1.jpg";
import CrmphoneImg2 from "../../static/imgs/crmphone2.jpg";
import BeidoudataImg from "../../static/imgs/beidoudata.jpg";
import BeidoudataImg1 from "../../static/imgs/beidoudata1.jpg";
import XietongdataImg from "../../static/imgs/xietongdata.png";
import MoruicrmImg1 from "../../static/imgs/moruicrm1.png";
import MoruicrmImg2 from "../../static/imgs/moruicrm2.png";
import MoruicrmImg3 from "../../static/imgs/moruicrm3.png";
import Mingtangmini1 from "../../static/imgs/mingtangmini1.png";
import Mingtangmini2 from "../../static/imgs/mingtangmini2.png";
import Mingtangmini3 from "../../static/imgs/mingtangmini3.png";

import store from "../../store/index";

const data = [
  {
    title: "上海北斗官网",
    des1: "上海北斗卫星导航平台有限公司官方网站，网站设计，网站研发",
    url: "http://www.shbeidou.com/",
    img: BeidouImg,
  },
  {
    title: "上海碳中和官网",
    des1: "上海碳中和联盟官网，网站设计，网站研发",
    url: "http://www.stiacn.com/",
    img: TanzhongheImg,
  },
  {
    title: "电子制造业协同创新平台",
    des1: "电子制造业协同创新平台，网站设计，网站研发，平台构建，系统维护工作等",
    url: "https://www.iccip.org.cn/",
    img: DianziImg,
  },
  {
    title: "收账APP (安卓ipad)",
    des1: "银行催帐系统，用于安卓系统ipad使用，使用技术flutter，基于保密协议，无法展示详情",
    img: CuishouImg,
    url: null,
    imgs: [CuishouImg, CuishouImg1],
  },
  {
    title: "管理系统手机端APP",
    des1: "企业内部管理系统手机端，使用React native技术",
    img: CrmphoneImg,
    url: null,
    imgs: [CrmphoneImg, CrmphoneImg1, CrmphoneImg2],
    flag: 1,
  },
  {
    title: "名堂秀（微信小程序）",
    des1: "涉及部分隐私，不便全部展示",
    img: Mingtangmini1,
    imgs: [Mingtangmini1, Mingtangmini2, MoruicrmImg3],
  },
  {
    title: "上海北斗大数据展示",
    des1: "上海北斗大数据展示仓，使用React + Echarts技术实现",
    img: BeidoudataImg,
    imgs: [BeidoudataImg, BeidoudataImg1],
    url: null,
  },
  {
    title: "电子制造业创新平台展示舱",
    des1: "电子制造业协同创新平台大数据展示舱，使用技术React + Echarts",
    img: XietongdataImg,
    // imgs: [BeidoudataImg, BeidoudataImg1],
    url: "https://dashboard.iccip.org.cn/",
  },
  {
    title: "企业后台管理系统",
    des1: "涉及部分隐私，不便全部展示",
    img: MoruicrmImg1,
    imgs: [MoruicrmImg1, MoruicrmImg2, MoruicrmImg3],
  },
];
const Project = () => {
  const [inx, setInx] = useState(0);
  const [flag, setFlag] = useState(false);
  const [plus, setPlus] = useState(false);
  const [overInx, setOverInx] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    store.subscribe(() => {
      setAmount(store.getState().amount);
    });
  }, []);

  return (
    <div
      style={{
        position: "relative",
        padding: "0 0.3rem",
      }}
    >
      <section style={{ borderTop: CutLine, padding: "0 0.5rem" }}>
        <ul
          style={{
            display: "flex",
            padding: "0.3rem 0 0.2rem 0",
            flexWrap: "wrap",
            justifyContent: "space-between",
            border: CutLine,
            borderTop: "none",
          }}
        >
          {data.map((item, index) => {
            return (
              <li
                key={index}
                style={{
                  width: "30%",
                  height: "2.7rem",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: "0.6rem",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onClick={() => {
                  setInx(index);
                }}
                onMouseOver={() => {
                  setOverInx(index);
                  setPlus(!plus);
                }}
              >
                {/* 文案 */}
                {
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: "rgba(0,0,0,0.65)",
                      color: "white",
                      zIndex: 7777,
                      fontSize: "0.12rem",
                      textAlign: "justify",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "0 0.08rem",
                      alignItems: "center",
                      textJustify: "inter-ideograph",

                      transform:
                        inx == index && flag
                          ? "translateY(0%)"
                          : "translateY(100%)",
                      transition: "all 1s",
                    }}
                    onClick={() => {
                      setFlag(false);
                    }}
                  >
                    <p style={{ textIndent: "0.24rem", lineHeight: "0.2rem" }}>
                      {" "}
                      {item.des1}
                    </p>
                    <p
                      style={{ textIndent: "0.24rem", lineHeight: "0.2rem" }}
                    ></p>
                    {item.url && (
                      <a
                        style={{ color: "red", fontSize: "0.16rem" }}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        查看项目
                      </a>
                    )}
                  </div>
                }
                {item.url ? (
                  <img
                    src={
                      item.img
                        ? item.img
                        : `${AliOss}/new_version_0518/committee/committee_${
                            index + 1
                          }.png`
                    }
                    style={{
                      height: "2rem",
                      // width: "100%",
                      transform:
                        overInx == index && plus ? "scale(1.2)" : "scale(1)",
                      transition: "all 1s",
                    }}
                    alt=""
                    onClick={() => {
                      setFlag(true);
                    }}
                  />
                ) : (
                  <Image.PreviewGroup items={item.imgs}>
                    <Image
                      src={item.img}
                      style={{
                        height: "2rem",
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </Image.PreviewGroup>
                )}
                <section
                  style={{
                    flex: 1,
                    background: "#F9F9F9",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 777,
                  }}
                  onClick={() => {
                    setFlag(true);
                  }}
                >
                  <span style={{ fontSize: "0.14rem", color: "rgba(0,0,0,1)" }}>
                    {item.title}
                  </span>
                </section>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export default Project;
