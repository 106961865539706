import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../page/home";
import ContactUs from "../page/contact/index";
import Cabinet from "../page/cabinet/index";
import Project from "../page/project/index";

const Routers = () => {
  return (
    <>
      <Switch>
        <Route
          path="/contact"
          exact
          render={() => {
            return <ContactUs />;
          }}
        />

        <Route
          path="/project"
          exact
          render={() => {
            return <Project />;
          }}
        />

        <Route
          path="/cabinet"
          exact
          render={() => {
            return <Cabinet />;
          }}
        />

        <Route
          path="/"
          exact
          render={() => {
            // return <Home />;
            return <Project />;
          }}
        />
      </Switch>
    </>
    // </Router>
  );
};

export default Routers;
