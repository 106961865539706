import { Button, message } from "antd";
import {
  BrowserRouter as Router,
  withRouter,
  useHistory,
} from "react-router-dom";
import Routers from "../route/index";
import { useEffect, useState } from "react";
import { messageTips } from "../apis/index";
import { portrait } from "../apis/index";
import Logo from "../../src/static/imgs/logo.jpg";

import Style from "./app.module.scss";

// const titles = ["首页", "业务范围", "联系我们"];

var titles = [
  // { path: "/", name: "首页" },
  // { path: "/cabinet", name: "产品设计" },
  // { path: "/", name: "项目展示" },
  // { path: "/contact", name: "联系我们" },
  // { inx: 3, path: "/scope" },
  // { inx: 4, path: "/council" },
  // { inx: 5, path: "/consult" },
  // { inx: 6, path: "/contact" },
];

let HeaderCmt = () => {
  const [flag, setFlag] = useState(false); //隐藏title
  const [inx, setInx] = useState(0);
  const [logined, checkLogin] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [tips, setTips] = useState(0);
  const [hide, setHide] = useState(false);
  const [result, setResult] = useState(false);
  const [company, setCompany] = useState("");
  const history = useHistory();

  useEffect(() => {
    // const unlisten = history.listen((href) => {
    //   href = href.pathname;
    //   if (href.indexOf("register") != -1 || href.indexOf("login") != -1) {
    //     setFlag(true);
    //   } else {
    //     setFlag(false);
    //   }
    // });
    // return () => {
    //   unlisten();
    // };
  }, [history]);

  useEffect(() => {
    let href = window.location.href;
    if (href.indexOf("cabinet") != -1) {
      setInx(1);
    } else if (href.indexOf("project") != -1) {
      setInx(2);
    } else if (href.indexOf("contact") != -1) {
      setInx(3);
    } else if (href.indexOf("/") == -1) {
      setInx(0);
    }
  }, []);

  useEffect(() => {
    const check = localStorage.getItem("user");
    if (JSON.parse(check)) {
      checkLogin(true);
      setUserInfo(JSON.parse(check));
      _messageTips();
    } else {
      checkLogin(false);
    }
  }, []);

  const _portrait = async () => {
    const res = await portrait(company);
    if (res && res.code == 2000) {
      setResult(res.result);
      localStorage.setItem("search", JSON.stringify(res.result));
      // history.push("/")
      setInx(null);
      setCompany("");
      history.push("/result", { value: JSON.stringify(res.result) });
    } else {
      message.warn("未查询到该公司数据！");
    }
  };

  const _messageTips = async () => {
    const res = await messageTips();
    if (res && res.code === 2000) {
      setTips(res.result);
    }
  };

  return (
    <div
      className={Style.index}
      style={{
        display: flag ? "none" : " block",
      }}
    >
      <header className={Style.headerBox}>
        <section
          className={Style.headerLeft}
          onClick={() => {
            history.push("/");
            setInx(0);
          }}
        >
          <img src={Logo} alt="" />
          <div
            style={{
              height: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              boxSizing: "border-box",
            }}
          >
            <span
              style={{
                fontSize: "0.16rem",
                fontWeight: "bold",
                color: "black",
              }}
            ></span>
            <span style={{ color: "rgba(0,0,0,0.6)", fontSize: "0.14rem" }}>
              项目展示
            </span>
          </div>
        </section>
        <section className={Style.headerRight}>
          <div
            style={{
              height: "50%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "0.04rem 0.19rem 0.04rem 0",
            }}
          >
            <a
              alt="#"
              href="http://43.156.27.23:2022/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#7B7B7B",
                fontSize: "0.12rem",
                fontWeight: "bold",
                width: "0.5rem",
                cursor: "pointer",
                borderBottom: "1px solid green",
                padding: "0.05rem 0rem",
                marginRight: "0.15rem",
                // border:"1px solid red"
              }}
            >
              技术博客
            </a>
            {/* <span
              style={{
                color: "rgba(0,0,0,0.5)",
                fontSize: "0.12rem",
                width: "0.5rem",
                cursor: "pointer",
                borderBottom: "1px solid green",
                padding: "0.05rem 0rem",
                display: "inline-block",
              }}
            >
              后台系统
            </span> */}
          </div>
          {/* <ul className={Style.tabBox}>
            {titles.map((item, index) => {
              return (
                item && (
                  <li
                    className={`${Style.liBox} ${
                      index == inx && Style.liBoxActive
                    }`}
                    onClick={() => {
                      history.push(item.path);
                      setInx(index);
                    }}
                  >
                    {item.name}
                  </li>
                )
              );
            })}
          </ul> */}
        </section>
      </header>
      {/* <div style={{ height: "0.78rem" }}></div> */}
    </div>
  );
};

HeaderCmt = withRouter(HeaderCmt);

function App() {
  const [flag, setFlag] = useState(false); //隐藏title

  useEffect(() => {
    console.log(window.location.href);
    let href = window.location.href;
    if (href.indexOf("register") != -1 || href.indexOf("login") != -1) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, []);

  return (
    <Router>
      <div className={Style.app}>
        {!flag && <HeaderCmt />}
        <main style={{ position: "relative" }} id="main_container">
          <Routers />
        </main>
      </div>
    </Router>
  );
}

export default App;
